'use client';

import type { RPCOutputs } from '@/types/rpc';
import { api } from '@/data/client';

import ErrorView from '@/views/components/Error';
import { AlertDialog, Callout } from '@radix-ui/themes';
import { ScrollIcon } from 'lucide-react';

export default function UserOnboardingWidget(props: {
  data?: RPCOutputs['user']['me'];
}) {
  const { data, isLoading, isError, error } = api.user.me.useQuery(undefined, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: !!props.data === false,
    initialData: props.data,
  });

  if (!data) {
    if (isLoading) {
      // return <div>Loading...</div>;
    }

    if (isError) {
      return <ErrorView error={error as any} />;
    }

    return null;
  }

  // TODO: if  agreement is not signed, show agreement in alert dialog, log out if they decline
  // TODO: if terms updated, show updated terms in alert dialog, log out if they decline (compare sign date to terms updated date)
  if (!data.acceptTermsAndConditions) {
    return (
      <AlertDialog.Root>
        <AlertDialog.Content>
          <AlertDialog.Title>Terms and Conditions</AlertDialog.Title>
          <AlertDialog.Description>
            You must accept the terms and conditions to continue.
          </AlertDialog.Description>

          <AlertDialog.Action>Accept</AlertDialog.Action>
          <AlertDialog.Action>Decline</AlertDialog.Action>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }

  // TODO: fill in user info if not filled in. make it dismissable
  // if (!data.profile?.firstname || !data.profile?.lastname || !data.name) {
  //   return (
  //     <Callout.Root color="blue" size="3">
  //       <Callout.Icon>
  //         <ScrollIcon />
  //       </Callout.Icon>
  //       <Callout.Text>
  //         Please fill in your profile information to continue.
  //       </Callout.Text>
  //     </Callout.Root>
  //   );
  // }

  return null;
}
